import React, { useEffect, useState, useCallback } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import { Dropdown, DropdownButton, FormControl, InputGroup } from 'react-bootstrap'
import debounce from 'lodash/debounce'

import CustomTooltip from 'components/CustomTooltip'
import { F_CUSTOM_DOMAIN } from 'constants/plans/constants'
import { hasFeature, isPhpFile } from 'utils/general'
import { showCreateSiteModal, showCustomDomainModal, showMessageModal } from 'Manage/actions'
import { showUpgradeCardModal } from 'actions'
import { ACTION_CREATE, ACTION_UPDATE } from 'components/CreateUpdateSiteModal/interface'
import { checkDomainExist } from 'services/custom-domain'

const DomainForm = ({
  name,
  initialValues,
  userProfile,
  action,
  disableProFeatures,
  setFieldValue,
  uploadValues,
  updatingPhpFile,
  setDomainError,
  domainError
}) => {
  const dispatch = useDispatch()
  const { domainSuffix, customDomains, tlds } = initialValues
  const { selectedFile } = uploadValues
  const { productId, siteLimit } = userProfile
  const customDomainSuffix = localStorage.getItem('active_custom_domain')
  const [domainExists, setDomainExists] = useState(false)
  const { values, setFieldTouched, validateForm } = useFormikContext()
  const [originalSubdomain, setOriginalSubdomain] = useState('')

  const onAddCustomDomain = () => {
    dispatch(showCreateSiteModal(false))

    if (disableProFeatures || !hasFeature(productId, F_CUSTOM_DOMAIN)) {
      dispatch(
        showUpgradeCardModal({
          title: 'Upgrade to connect your own website domain',
          desc: 'Use your own brand and upload content to your own website domain',
          cta: 'Connect domain'
        })
      )
    } else if (siteLimit === customDomains.length) {
      dispatch(showMessageModal({ title: 'Limit reached', message: 'Custom domain limit reached' }))
    } else {
      dispatch(showCustomDomainModal(true))
    }
  }

  const isCustomDomain = (domainSuffix) => !tlds.includes(domainSuffix)

  const onSelectDomainSuffix = (eK, event) => {
    const selectedDomainSuffix = event.target.text
    setFieldValue(`${name}.domainSuffix`, selectedDomainSuffix)
    setFieldValue(`${name}.isCustomDomain`, isCustomDomain(selectedDomainSuffix))
  }

  const isDomainExists = debounce(async (subdomain) => {
    if (subdomain) {
      if (originalSubdomain == '' && action === ACTION_UPDATE) {
        setDomainError(null)
        return
      }
      if (subdomain === originalSubdomain) {
        setDomainError(null)
        return
      }

      try {
        const response = await checkDomainExist({ domain: subdomain + domainSuffix })
        setDomainExists(response.data.exists)
        if (response.data.exists) {
          setDomainError(response.data.message)
        } else {
          setDomainError(null)
        }
      } catch (error) {
        console.error('Error checking domain:', error)
      }
    } else {
      setDomainError(null)
    }
    setFieldTouched(`${name}.subdomain`, true, false)
    validateForm()
  }, 300)

  useEffect(() => {
    setOriginalSubdomain(values[name].subdomain)
  }, [])

  useEffect(() => {
    isDomainExists(values[name].subdomain)
  }, [values[name].subdomain, domainSuffix])

  useEffect(() => {
    if (!!customDomainSuffix) {
      const foundDomain = customDomains.find(
        (item) => `.${item.domain.toLowerCase()}` === customDomainSuffix.toLowerCase()
      )
      if (!!foundDomain && !tlds.includes(customDomainSuffix)) {
        setFieldValue(`${name}.isCustomDomain`, true)
        setFieldValue(`${name}.domainSuffix`, customDomainSuffix)
        setFieldValue(`${name}.subdomain`, localStorage.getItem('active_custom_subdomain'))
      }
    }
  }, [customDomainSuffix])

  useEffect(() => {
    if (isPhpFile(selectedFile)) {
      setFieldValue(`${name}.domainSuffix`, '.tiiny.io')
    }
  }, [selectedFile])

  const handleBlur = (e) => {
    // Prevent Formik from clearing the error on blur
    e.preventDefault()
  }

  return (
    <>
      <InputGroup className="input-domain">
        <InputGroup>
          {/** SUBDOMAIN */}
          <Field
            name={`${name}.subdomain`}
            as={FormControl}
            placeholder="link-name"
            aria-label="Subdomain"
            disabled={updatingPhpFile}
            onBlur={handleBlur}
          />
          {/** END SUBDOMAIN */}

          {/** DOMAIN SUFFIX */}
          <DropdownButton
            id="input-group-dropdown-2"
            variant="primary"
            as={InputGroup.Append}
            title={domainSuffix}
            value={domainSuffix}
            onSelect={onSelectDomainSuffix}
            disabled={action !== ACTION_CREATE}
          >
            {/**  select / create custom suffix domain  */}
            {tlds?.map((tld) => (
              <Dropdown.Item key={tld} value={tld}>
                {tld}
              </Dropdown.Item>
            ))}
            {customDomains?.map((customDomain) => {
              return (
                <Dropdown.Item
                  key={customDomain}
                  value={`.${customDomain.domain}`}
                  active={domainSuffix === `.${customDomain.domain}`}
                >
                  .{customDomain.domain}
                </Dropdown.Item>
              )
            })}
            {!isPhpFile(selectedFile) && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item key={1} onClick={onAddCustomDomain}>
                  <span style={{ fontSize: '14px', color: '#333' }}>
                    Add custom domain{' '}
                    {disableProFeatures && <img alt="Upgrade" src="/assets/icons/pro-black.png" height="20" />}
                  </span>
                </Dropdown.Item>
              </>
            )}
            {/** */}
          </DropdownButton>
          {/** END DOMAIN SUFFIX */}
        </InputGroup>
      </InputGroup>

      {/** ERROR DOMAIN */}
      <ErrorMessage
        name={`${name}.subdomain`}
        render={() => (
          <div className="mt-1 validation-error text-center">
            {domainExists ? `${domainError}` : ''}
            {!isCustomDomain(domainSuffix) && !domainExists && 'Please enter a valid link-name.'}
            {isCustomDomain(domainSuffix) && !domainExists && 'Please enter a valid subdomain or "www"'}
            <CustomTooltip label="Please select a different link name for your site.">
              <span className="link ms-2">
                <u>Help</u>
              </span>
            </CustomTooltip>
          </div>
        )}
      />
      {/** END ERROR DOMAIN */}
    </>
  )
}

export default DomainForm
