import React, { useState, useEffect } from 'react'
import { Button, Spinner, Container, Row, Col, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { claimSite } from 'services/manage'
import { showUpgradeCardModal } from 'actions'
import { onUpgradePlan, pluralize, getErrorMessage } from 'utils/general'
import { fetchUserData } from 'Manage/actions'
import * as Sentry from '@sentry/react'
import { ErrorBox } from 'components/ErrorBox/ErrorBox'
/**
 * @todo : make this component dynamic for other use cases (later maybe)
 */

const ClaimSiteModal = ({ userProfile, onClose, liveSitesCount, show }) => {
  const dispatch = useDispatch()
  const [subdomain, setSubdomain] = useState('')
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()

  useEffect(() => {
    // Extract the gpt-domain parameter from the URL
    const urlParams = new URLSearchParams(window.location.search)
    const gptDomain = urlParams.get('gpt-domain')
    if (gptDomain) {
      setSubdomain(gptDomain) // Set the subdomain state
    }
  }, [])

  const onRetry = () => {
    setHasError(false)
    setLoading(false)
  }

  const handleClaimSite = () => {
    setLoading(true)

    const siteLimit = userProfile.siteLimit || 1

    if (siteLimit <= liveSitesCount) {
      dispatch(
        showUpgradeCardModal({
          title: 'Upgrade to upload more content',
          desc: `Your current plan only allows ${siteLimit} live ${pluralize(
            'project',
            'projects',
            siteLimit
          )}. Upload more on a different plan.`,
          cta: 'Create more projects',
          onClick: () => onUpgradePlan({ projects: siteLimit }, dispatch)
        })
      )
      return
    }

    claimSite({ domain: subdomain })
      .then(() => {
        history.push('/manage')
        dispatch(fetchUserData())
        onClose()
      })
      .catch((error) => {
        console.error(error)
        setHasError(true)

        const errorMessage = getErrorMessage(error)
        if (errorMessage && errorMessage !== '') {
          setErrorMessage(getErrorMessage(error))
        } else if (errorMessage && errorMessage === '') {
          setErrorMessage('Oops, something went wrong')
          console.error(error)
        } else {
          setErrorMessage('Oops, something went wrong')
          Sentry.captureException(error, {
            tags: {
              section: 'claim-site-error'
            }
          })
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal show={show} size="xs" onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      {hasError ? (
        <ErrorBox onClick={onRetry} message={errorMessage || 'Oops, something went wrong'} />
      ) : (
        <Container fluid>
          <Row className={'flex-column-reverse flex-lg-row'}>
            <Col lg={12} className={`p-4 bg-white ${'rounded-2'}`}>
              <div className="success-box">
                <div className="float-right" onClick={onClose}>
                  <img className="exit" src="delete.png" alt="quit" />
                </div>
                <h3 className="bold">Success</h3>
                <img className="celebrate" src="celebrate.gif" alt="celebrate" />
                <div>
                  <h5 className="domain">
                    <a href={`https://${subdomain}`} target="_blank" rel="noreferrer">
                      {subdomain}
                    </a>
                  </h5>
                  <div className="d-flex flex-column mt-2 mb-3 align-items-center">
                    <div className="mt-2 d-flex align-items-center flex-row">
                      <Button className="me-2 btn-lg" variant="primary" onClick={handleClaimSite}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Claim site'}
                      </Button>
                    </div>
                    <div className="d-flex align-items-center justify-items-center mt-3 gap-1 font-small">
                      <span className="d-inline">Something broken? -</span>
                      <a
                        className="d-inline link"
                        href="https://helpdesk.tiiny.host/en/category/common-issues-wocoo1/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read our help guide
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </Modal>
  )
}
export default ClaimSiteModal
