import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { withRouter } from 'react-router'
import api from '../utils/api'
import { API_ROOT } from '../constants'
import Footer from '../components/Footer'
import HomeHeader from '../components/HomePageHeader'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './Connect.module.css'
import { getIntegrationData } from './integrations'

const Connect = () => {
  const history = useHistory()
  const location = useLocation()
  let integrationData
  const queryParams = new URLSearchParams(location.search)
  const integration = queryParams.get('integration')
  const platform = queryParams.get('platform')
  const [userProfile, setUserProfile] = useState({})
  const [integrationType, setIntegrationType] = useState(integration)

  if (integration) {
    integrationData = getIntegrationData(integration)
  } else {
    integrationData = getIntegrationData('canva')
  }

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.setItem('redirect-url', window.location.href)
      history.push('/login')
    }
    fetchUserData()
  }, [])

  const fetchUserData = () => {
    api
      .post(`${API_ROOT}/pro/manage`)
      .then(async (res) => {
        const data = res.data

        setUserProfile(data.userProfile)
      })
      .catch((err) => {
        window.location = '/login'
      })
  }

  const handleAuthorize = () => {
    if (integrationType === 'vscode') {
      api
        .get(`${API_ROOT}/vscode/configuration/authorize`)
        .then(async (res) => {
          try {
            await api.get(`http://localhost:54322/callback?email=${res.data.email}&apiKey=${res.data.apiKey}`)
          } finally {
            let scheme = platform ? platform : 'vscode'
            window.location.replace(`${scheme}://TiinyHost.tiinyhost`)
          }
        })
        .catch((err) => {
          console.error('fetchUserProfile', err)
        })

      history.push('/manage')
    } else {
      const queryParams = new URLSearchParams(location.search)
      const canvaUserToken = queryParams.get('canva_user_token')
      const state = queryParams.get('state')

      // Send a GET request with the extracted query parameters
      api
        .get(`${API_ROOT}/canva/configuration/authorize?canva_user_token=${canvaUserToken}&state=${state}`)
        .then(async (res) => {
          window.location.replace(`https://www.canva.com/apps/configured?success=true&state=${state}`)
        })
        .catch((err) => {
          console.error('fetchUserProfile', err)
        })
    }
  }

  const handleCancel = () => {
    history.push('/manage')
  }

  return (
    <div className={`body-bg full-height ${styles.bodyBg}`}>
      <HomeHeader email={userProfile.email} productCode={userProfile.productCode} proMode />
      <div className={styles.containerLogin}>
        <div className={styles.containerLeftB}>
          <h3 className="text-start">
            <b>Authorize {integrationData.name} to access your account?</b>
          </h3>

          <span className="d-flex flex-row w-100">
            <Button className={`mt-2 d-inline-block w-75 me-1 ${styles.button}`} onClick={handleAuthorize}>
              Authorize
            </Button>
            <Button className={`mt-2 d-inline-block w-25 ${styles.button}`} variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </span>
          <div className="mt-5">
            <p className="text-start">
              <strong>This application will be able to:</strong>
            </p>
            <ul className={`text-start  ${styles.ul}`}>
              <li className={`${styles.li}`}>See all your links</li>
              <li className={`${styles.li}`}>Create, and update links for you </li>
              <li className={`${styles.li}`}>See your email address.</li>
            </ul>
          </div>
        </div>
        <div className={styles.containerRight}>
          <div style={{ textAlign: 'left' }}>
            <img src={integrationData.logo} className={`${styles.img}`} />
          </div>

          <br></br>
          <br></br>
          <h5 className="text-start">
            <b>By {integrationData.name}</b>
          </h5>
          <p className="text-start w-75">
            {integrationData.url}
            <br></br>
            {integrationData.desc}
            <br></br>
            <br></br>
            <a href={integrationData.privacyPolicyUrl}>Privacy Policy</a>
            <br></br>
            <br></br>
            <a href={integrationData.termsAndConditionsUrl}>Terms and Conditions</a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default withRouter(Connect)
