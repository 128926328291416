import React, { memo } from 'react'
import DropzoneWrapper from './component/DropzoneWrapper'
import { DROPZONE_ACCEPTED_FILES, DROPZONE_ACCEPTED_FILES_PRO } from '../../constants'
import { useSelector } from 'react-redux'
import { PLANS } from '../../constants/plans'

const DragWrapper = memo(({ children, trialAccount, siteLimit, liveSites }) => {
  const updateModalOpen = useSelector((state) => state.manage.displayModal)
  const userProfile = useSelector((state) => state.manage.userProfile)
  const isPro = userProfile.productId === PLANS.PRO_U.id

  return (
    <div>
      <DropzoneWrapper
        updateModalOpen={updateModalOpen}
        trialAccount={trialAccount}
        siteLimit={siteLimit}
        liveSites={liveSites}
        acceptedFiles={isPro ? DROPZONE_ACCEPTED_FILES_PRO : DROPZONE_ACCEPTED_FILES}
      >
        {children}
      </DropzoneWrapper>
    </div>
  )
})

export default DragWrapper
