import { DISALLOWED_EMAILS, ERROR_MSGS, MAX_FILE_SIZE, MAX_PDF_SIZE, TLD_S3_PATH_CONFIG, TLDS } from '../constants'
import { PLANS_ID, PLANS, PLAN_THRESHOLDS } from '../constants/plans'
import JSZip from 'jszip'
import { FILE_TYPE_HTML, FILE_TYPE_PDF } from 'components/CreateUpdateSiteModal/interface'
import { setShowPaymentModal } from 'actions'
import {
  imageExtensions,
  excelExtensions,
  docExtensions,
  pptExtensions,
  videoExtensionsBrowserNative,
  audioExtensionsBrowserNative,
  codeExtensionsBrowserNative
} from '../helpers/file-type'

export function isPDF(file) {
  return file.type === FILE_TYPE_PDF
}

export const isHtml = (file) => {
  return file.type === FILE_TYPE_HTML
}

export const isPhpFile = (file) => {
  return (
    file?.type?.toLowerCase().includes('php') ||
    file?.fileType?.toLowerCase().includes('php') ||
    file?.name?.toLowerCase().includes('php')
  )
}

export const convertToBytes = (size) => 1024 * 1024 * size

export function formatMbToGb({ mb, pow = 1 }) {
  const k = 1024
  return Number(Number(mb / Math.pow(k, pow)).toFixed(4))
}

export function isValidFileSize(file, maxSize) {
  if (maxSize) {
    return file.size < convertToBytes(maxSize)
  } else if (file.type === FILE_TYPE_PDF) {
    return file.size < convertToBytes(MAX_PDF_SIZE)
  } else return file.size < convertToBytes(MAX_FILE_SIZE)
}

export function isValidFileSizeV2(initialFile, filesArray, maxSize) {
  const totalSize = filesArray.reduce((accumulator, file) => accumulator + file.size, initialFile.size)
  if (maxSize) {
    return totalSize < convertToBytes(maxSize)
  }
  const maxFileSize = initialFile.type === FILE_TYPE_PDF ? MAX_PDF_SIZE : MAX_FILE_SIZE
  return totalSize < convertToBytes(maxFileSize)
}

export const pluralize = (singular, plural, count) => (count === 1 ? singular : plural)

export function formatNumber(n) {
  const nFormat = new Intl.NumberFormat()
  return nFormat.format(n)
}

export function formatStorage(sizeMB) {
  if (sizeMB >= 1000) {
    const n = sizeMB / 1000
    return `${n} GB`
  }
  return `${sizeMB} MB`
}

export const copyToClipboard = (text) => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const getDirectFileLink = (root, filename, ext, dateCreated) => {
  if (!filename) return null
  else return `${root}/${filename.replace(`.${ext}`, '')}-${dateCreated.replace(/:|\\./g, '-')}.${ext}`
}

export const validateDisallowedEmails = (email) =>
  !DISALLOWED_EMAILS.some((e) => email.match(`${e}$`)) && !email.includes('+')

export const getErrorMessage = (err) => ERROR_MSGS[err?.response?.data?.code || err?.code]

export function isDevSite() {
  return (
    window.location.hostname.includes('tiiny.co.uk') ||
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  )
}

export function isPreviewSite() {
  return window.location.hostname.includes('preview.tiiny.co.uk')
}

export function isDevOrPreviewSite() {
  return isDevSite() || isPreviewSite()
}

export const mbValue = (mb) => 1024 * 1024 * mb

export const isTrialAccount = ({ productId }) => !productId

export const isMac = () => navigator?.platform?.toUpperCase().indexOf('MAC') > -1

export function hashString(str = '') {
  let hash = 0,
    i,
    chr
  if (str?.length === 0) return hash
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export const isCustomDomain = (url) => !Object.values(TLDS).some((tld) => url.endsWith(tld))

export const getTLDs = ({ account }) => {
  if (isPreviewSite()) return [TLDS.TIINY_CO_UK]
  if (isDevSite()) return [TLDS.TIINY_CO_UK]
  return account?.validated ? [TLDS.TIINY_CO, TLDS.TIINY_SITE] : [TLDS.TIINY_SITE]
}

export const getSubdomainAndTLD = ({ site }) => {
  const { subdomain } = site
  let splitSubdomain = subdomain?.split('.')
  if (isPreviewSite()) {
    splitSubdomain = subdomain?.replace('preview', '')?.split('.')
  }
  return splitSubdomain ? { subdomain: splitSubdomain[0], tld: `.${splitSubdomain.slice(1).join('.')}` } : {}
}

export const getS3Path = ({ site, isCustomDomain }) => {
  const { subdomain, tld } = getSubdomainAndTLD({ site })
  return isCustomDomain ? `${tld.substring(1)}/${subdomain}` : TLD_S3_PATH_CONFIG[tld]({ subdomain, tld })
}

export const hasFeature = (planId, feature) => PLANS_ID[planId]?.features[feature]

// Helper function to create a file input element
export const createFileInput = (acceptedFiles) => {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = acceptedFiles || '*/*'

  return input
}

// Helper function to check if a file is valid based on type and size
export const isValidFile = (fileSelected, selectedFile, files, maxSizeMB) => {
  const updatedFiles = [...files, selectedFile]
  if (maxSizeMB) {
    return isValidFileSizeV2(fileSelected, updatedFiles, maxSizeMB)
  } else {
    return isValidFileSizeV2(fileSelected, updatedFiles, maxSizeMB)
  }
}

export async function zipMultipleFiles(addedFiles) {
  const zip = new JSZip()
  addedFiles.forEach((file) => {
    zip.file(file.path, file)
  })
  const content = await zip.generateAsync({ type: 'blob' })

  return new File([content], 'files.zip', {
    type: 'application/zip',
    lastModified: new Date()
  })
}

export const getFullDomainValue = (subdomain = '', domainSuffix = '') => {
  return subdomain + domainSuffix
}

export const getDomainSuffix = (subdomain) => {
  let splitSubdomain = subdomain?.split('.')
  if (isPreviewSite()) {
    splitSubdomain = subdomain?.replace('preview', '')?.split('.')
  }
  return subdomain ? { subdomain: splitSubdomain[0], tld: `.${splitSubdomain.slice(1).join('.')}` } : {}
}

export const convertToFormData = (formData, key, value) => {
  if (value !== null && value !== undefined) {
    formData.append(key, value)
  }
}

export const formDataToJson = (formData) => {
  const jsonObject = {}
  for (const [key, value] of formData.entries()) {
    jsonObject[key] = value
  }
  return jsonObject
}

export const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true
  }

  if (typeof value === 'string') {
    return value.trim() === ''
  }

  if (Array.isArray(value)) {
    return value.length === 0
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0
  }

  return false
}

export const formatFileSize = (sizeInBytes) => {
  // Convert size in bytes to megabytes (MB)
  const sizeInMB = sizeInBytes / (1024 * 1024)

  // Format the size to two decimal places
  const formattedSize = sizeInMB.toFixed(2)

  // Return the formatted size in MB
  return `${formattedSize} MB`
}

export const isFileSizeGreaterThan3MB = (sizeInBytes) => {
  const megabyte = 1024 * 1024
  return sizeInBytes > 3 * megabyte
}

export const validateFilesSelection = (files, selectedFile) => {
  for (const file of files) {
    if (
      file?.path === selectedFile.path ||
      file?.name === selectedFile.name ||
      file?.s3FileName === selectedFile.name
    ) {
      return false
    }
  }
  return true
}

export const getDropzoneAcceptedFiles = (fileType) => {
  let acceptedFilesType = {
    'application/pdf': ['.pdf'],
    'text/html': ['.html', '.htm'],
    'application/zip': ['.zip'],
    'application/x-zip-compressed': ['.zip'],
    'text/php': ['.php'],
    image: imageExtensions.map((ext) => `.${ext}`),
    excel: excelExtensions.map((ext) => `.${ext}`),
    doc: docExtensions.map((ext) => `.${ext}`),
    ppt: pptExtensions.map((ext) => `.${ext}`),
    video: videoExtensionsBrowserNative.map((ext) => `.${ext}`),
    audio: audioExtensionsBrowserNative.map((ext) => `.${ext}`),
    code: codeExtensionsBrowserNative.map((ext) => `.${ext}`)
  }

  if (fileType) {
    return Object.values(acceptedFilesType[fileType]).flat()
  }

  return Object.values(acceptedFilesType).flat()
}

export const checkAccountAge = (created) => {
  const createdDate = new Date(created)
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const diffTime = Math.abs(currentDate - createdDate)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  // Check if the account is older than a day
  return diffDays > 1
}

export const isSameDate = (date1, date2) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

export const sortLoadingSites = (sites) => {
  const allSites = sites

  const mySites = allSites.reduce((acc, current) => {
    if (current?.linkStatus === 'processing') {
      const checker = acc.find(
        (site) => site.subdomain === current.subdomain && site?.linkStatus === current?.linkStatus
      )

      if (!checker) acc.push(current)
    }

    return acc
  }, [])

  return mySites
}

export const sortOnlineSites = (sites, sitesToAdd) => {
  const allSites = [...sitesToAdd, ...sites]

  const mySites = allSites.reduce((acc, current) => {
    if (current?.linkStatus === 'online' || current?.linkStatus === 'error' || !current?.linkStatus) {
      const checker = acc.find(
        (site) => site.subdomain === current.subdomain && site?.linkStatus === current?.linkStatus
      )

      if (!checker) acc.push(current)
    }

    return acc
  }, [])

  return mySites
}

export const sortDuplicateSites = (allSites, sitesToAdd) => {
  const sites = allSites.map((site) => {
    if (sitesToAdd?.length > 0) {
      for (let i = 0; i < sitesToAdd.length; i++) {
        if (site.subdomain === sitesToAdd[i].subdomain) {
          return sitesToAdd[i]
        }
      }
    }
    return site
  })

  return sites
}

export function isMoreThanOneDay(date) {
  const lastLogin = new Date(date)
  const now = new Date()
  const oneDayInMs = 24 * 60 * 60 * 1000 // Number of milliseconds in a day

  return now - lastLogin > oneDayInMs
}

export const getPlanIdByFeature = ({ fileSize, customDomains, projects, visitors, bandwidth }) => {
  if (
    fileSize > PLAN_THRESHOLDS.SOLO.fileSizeLimit ||
    (customDomains && customDomains >= PLAN_THRESHOLDS.SOLO.customDomainsLimit) ||
    (projects && projects >= PLAN_THRESHOLDS.SOLO.projectLimit) ||
    (visitors && visitors >= PLAN_THRESHOLDS.SOLO.visitorLimit) ||
    (bandwidth && bandwidth >= PLAN_THRESHOLDS.SOLO.bandwidthLimit)
  ) {
    return PLANS.PRO_U.id
  }

  if (
    fileSize > PLAN_THRESHOLDS.TINY.fileSizeLimit ||
    (customDomains && customDomains >= PLAN_THRESHOLDS.TINY.customDomainsLimit) ||
    (projects && projects >= PLAN_THRESHOLDS.TINY.projectLimit) ||
    (visitors && visitors >= PLAN_THRESHOLDS.TINY.visitorLimit) ||
    (bandwidth && bandwidth >= PLAN_THRESHOLDS.TINY.bandwidthLimit)
  ) {
    return PLANS.PRO.id
  }

  if (
    fileSize > PLAN_THRESHOLDS.FREE.fileSizeLimit ||
    (customDomains && customDomains > PLAN_THRESHOLDS.FREE.customDomainsLimit) ||
    (projects && projects >= PLAN_THRESHOLDS.FREE.projectLimit) ||
    (visitors && visitors >= PLAN_THRESHOLDS.FREE.visitorLimit) ||
    (bandwidth && bandwidth >= PLAN_THRESHOLDS.FREE.bandwidthLimit)
  ) {
    return PLANS.TINY.id
  }

  return PLANS.TINY.id
}

export const onUpgradePlan = (payload, dispatch) => {
  // gets the pricing plan id based on user action threshold
  const planId = getPlanIdByFeature(payload)
  if (planId) {
    dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: planId }))
  }
}

export const parseFilename = (filename, url) => {
  if (isCustomDomain(url)) {
    return 'document.pdf'
  }
  const lastDotIndex = filename.lastIndexOf('.')
  const name = lastDotIndex !== -1 ? filename.substring(0, lastDotIndex) : filename // Get the name without the last extension
  const ext = lastDotIndex !== -1 ? filename.substring(lastDotIndex) : '' // Get the last extension
  const sanitizedName = name.replace(/[-\s.]+/g, '-').replace(/['?>~!@%()"{}&#!<>:`+]+/g, '') // Sanitize the name

  return `${sanitizedName}${ext}` // Return the sanitized name with the last extension
}
