import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { getAreaCurrency, TIMEZONE } from '../../../../constants/pricing/currency'
import { getPayProCheckoutUrl } from 'services/manage'

const UPIPay = ({ payProId, email }) => {
  const isIndia = getAreaCurrency(TIMEZONE) === '₹'

  if (!isIndia) return null

  const handleCheckout = async () => {
    const {
      data: { url }
    } = await getPayProCheckoutUrl({ payProId, email })
    window.open(url, '_blank')
  }

  return (
    <Row className="mb-3">
      <Col>
        <Button className="tr-upi-pay w-100" variant="outline-dark" onClick={handleCheckout}>
          Pay with <img src="assets/icons/upi-logo.webp" height={30} /> /{' '}
          <img src="assets/icons/paytm-logo.png" height={20} />
        </Button>
      </Col>
    </Row>
  )
}

export default UPIPay
