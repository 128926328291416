import React, { useState, useEffect } from 'react'
import SocialLogin from '../components/SocialLogin/SocialLogin'
import { API_ROOT, ERROR_MSGS, REGEX_EMAIL } from '../constants'
import { errors } from '../constants'
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { validateDisallowedEmails } from 'utils/general'
import axios from 'axios'
import { getErrorMessage } from 'utils/general'

const LOGGED_IN = 'LOGGED_IN'
const REQUIRE_EMAIL = 'REQUIRE_EMAIL'
const REQUIRE_EMAIL_PASSWORD = 'REQUIRE_EMAIL_PASSWORD'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_ERROR = 'LOGIN_ERROR'
const LOGIN_ERROR_PASSWORD = 'LOGIN_ERROR_PASSWORD'
const LOADING = 'LOADING'
const REQUIRE_UPGRADE = 'REQUIRE_UPGRADE'

const DEFAULT_STATE = {
  stage: REQUIRE_EMAIL,
  error: undefined
}

const SignUpPage = () => {
  const [currentState, setCurrentState] = useState(DEFAULT_STATE)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [usePassword, setUsePassword] = useState(false)
  const history = useHistory()
  const isLoginPage = window.location.pathname === '/login'

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setCurrentState({ stage: LOGGED_IN })
    }
  }, [])

  const onEmailChange = (e) => {
    const email = e.target.value
    const isValidEmail = REGEX_EMAIL.test(email.toLowerCase()) && validateDisallowedEmails(email.toLowerCase())
    setEmail(email)
    setIsValidEmail(isValidEmail)
  }

  const onPasswordChange = (e) => {
    const password = e.target.value
    setPassword(password)
  }

  const onUsePasswordChange = () => {
    setUsePassword(usePassword !== true)
    setCurrentState(usePassword === true ? { stage: REQUIRE_EMAIL } : { stage: REQUIRE_EMAIL_PASSWORD })
  }

  const handleLogin = () => {
    const { stage } = currentState
    if (stage === LOGGED_IN) {
      history.push('/manage')
    } else if (stage === REQUIRE_EMAIL) {
      loginWithEmail()
    } else if (stage === REQUIRE_EMAIL_PASSWORD) {
      loginWithPassword()
    } else if (stage === LOGIN_SUCCESS || stage === LOGIN_ERROR || stage === LOGIN_ERROR_PASSWORD) {
      retryLogin()
    } else if (stage === REQUIRE_UPGRADE) {
      history.push('/pricing')
    }
  }

  const retryLogin = () => {
    setCurrentState(DEFAULT_STATE)
    setUsePassword(false)
  }

  const loginWithEmail = () => {
    setCurrentState({ stage: LOADING })

    const data = { email }

    const promoCode = localStorage.getItem('promoCode')
    if (promoCode) {
      data.promoCode = promoCode
    }

    axios
      .post(`${API_ROOT}/login`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        setCurrentState({ stage: LOGIN_SUCCESS })
      })
      .catch((err) => {
        if (err?.response?.data?.code === errors.USER_TRIAL_EXPIRED) {
          setCurrentState({ stage: REQUIRE_UPGRADE })
        } else if (err?.response?.data?.code === errors.INVALID_EMAIL) {
          setCurrentState({ stage: LOGIN_ERROR, error: errors.INVALID_EMAIL })
        } else {
          setCurrentState({ stage: LOGIN_ERROR })
        }
      })
  }

  const loginWithPassword = async () => {
    try {
      const data = { email, password }

      const res = await axios.post(`${API_ROOT}/auth/password`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      history.push(res.data.authLink)
      setCurrentState({ stage: LOGIN_SUCCESS })
    } catch (error) {
      setCurrentState({ stage: LOGIN_ERROR_PASSWORD })
    }
  }

  const handleKeyDown = (e) => e.key === 'Enter' && isValidEmail && handleLogin()

  const onSignOut = () => {
    localStorage.removeItem('token')
    history.go(0)
  }

  const { stage, error } = currentState

  return (
    <div className="container">
      <h5 className="text-start">
        <b>{(isLoginPage ? 'Login or ' : '') + 'Sign up'}</b>
      </h5>
      <div className="text-start mt-3">
        {(isLoginPage ? 'Manage your uploads from your dashboard.' : 'Sign up for an account to manage your uploads.')}
      </div>
      <div className="box-100 p-3 login-box mt-4 border-light-grey">
        <h5 className="text-center mb-3">{(isLoginPage ? 'Login or ' : '') + 'Sign up'}</h5>
        {stage === LOGGED_IN && <div className="login-message">You're logged in</div>}
        {stage === REQUIRE_EMAIL && (
          <InputGroup className="input-group">
            <Form.Control
              onChange={onEmailChange}
              onKeyPress={handleKeyDown}
              placeholder="Email"
              aria-label="email"
              autoFocus={true}
              type="email"
            />
          </InputGroup>
        )}

        {stage === REQUIRE_EMAIL_PASSWORD && (
          <InputGroup className="input-group">
            <div className="p-1 mt-1 w-100">
              <Form.Control
                onChange={onEmailChange}
                onKeyPress={handleKeyDown}
                placeholder="Email"
                aria-label="email"
                autoFocus={true}
                type="email"
              />
            </div>

            <div className="p-1 mt-1 w-100">
              <Form.Control
                onChange={onPasswordChange}
                onKeyPress={handleKeyDown}
                placeholder="Password"
                aria-label="password"
                autoFocus={true}
                type="password"
              />
            </div>
          </InputGroup>
        )}

        {stage === LOGIN_SUCCESS && (
          <div className="login-message">
            We've sent a login link to <b>{email}</b>
          </div>
        )}
        {stage === LOGIN_ERROR && (
          <div className="login-message">
            {getErrorMessage(error) || 'Sorry, unable to send you an email.'}
            <p className="link" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
              <u>Contact support</u>
            </p>
          </div>
        )}

        {stage === LOGIN_ERROR_PASSWORD && (
          <div className="login-message">
            {getErrorMessage(error) || 'Wrong email or password'}
            <p className="link" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
              <u>Contact support</u>
            </p>
          </div>
        )}
        {stage === REQUIRE_UPGRADE && (
          <div className="login-message ps-3 pe-3">{ERROR_MSGS[errors.USER_TRIAL_EXPIRED]}</div>
        )}
        {stage !== LOADING && (
          <Button
            className="action-btn w-100 mt-2"
            disabled={
              (stage !== LOGGED_IN && !isValidEmail) || (stage === REQUIRE_EMAIL_PASSWORD && isValidEmail && !password)
            }
            onClick={handleLogin}
          >
            {stage === LOGGED_IN && 'Continue'}
            {stage === REQUIRE_EMAIL && 'Email Me Login Link'}
            {stage === REQUIRE_EMAIL_PASSWORD && 'Login'}
            {stage === LOGIN_SUCCESS && 'Try again'}
            {stage === LOGIN_ERROR && 'Try again'}
            {stage === LOGIN_ERROR_PASSWORD && 'Try again'}
            {stage === REQUIRE_UPGRADE && 'Upgrade to Pro'}
          </Button>
        )}

        {isLoginPage && (stage === REQUIRE_EMAIL || stage === REQUIRE_EMAIL_PASSWORD) && (
          <div className="mt-1">
            <a className="font-small link" onClick={onUsePasswordChange}>
              {usePassword ? 'Login with email link' : 'Login with password'}
            </a>
          </div>
        )}

        {stage === LOGGED_IN && (
          <div className="mt-2 font-small">
            or{' '}
            <span className="link" onClick={onSignOut}>
              Sign out
            </span>
          </div>
        )}
        {stage === LOADING && (
          <div className="w-100 text-center">
            <Spinner className="mt-3 ms-auto mr-auto" animation="border" />
          </div>
        )}
        {(stage === REQUIRE_EMAIL || stage === REQUIRE_EMAIL_PASSWORD) && (
          <>
            <div className="separator mt-3 w-75 ms-auto me-auto">Or Login with</div>
            <SocialLogin className="mt-3" />
          </>
        )}
      </div>
      <div className="toc">
        By using our service you accept our{' '}
        <a href="https://tiiny.host/termsofservice.html" target="_blank" rel="noreferrer">
          <u>terms & conditions</u>
        </a>{' '}
        &{' '}
        <a href="https://tiiny.host/privacypolicy.html" target="_blank" rel="noreferrer">
          <u>privacy policy</u>
        </a>
      </div>
    </div>
  )
}

export default SignUpPage
