import { isDevOrPreviewSite } from '../../utils/general'
import { F_API_KEY, F_CUSTOM_DOMAIN, F_EDIT_MODE, F_EMAIL_GATE, F_PWD_PROTECTION } from './constants'

const PRODUCT_ID_PROD = 'prod_J8jz9uooW4PLna'
const PRODUCT_ID_DEV = 'prod_J8kEW4NCKrP3nK'

const PRICE_ID_YEARLY_PROD = 'price_1KfVHbKnX1nnNv6I4wEA7d2K'
const PRICE_ID_YEARLY_DEV = 'price_1KfVUXKnX1nnNv6IRgoMTn6b'

const PRICE_ID_MONTHLY_PROD = 'price_1KfV77KnX1nnNv6Iw1tVuVWl'
const PRICE_ID_MONTHLY_DEV = 'price_1KfVScKnX1nnNv6IzFaboaWJ'

const PADDLE_PRICE_ID_YEARLY_PROD = 761274
const PADDLE_PRICE_ID_YEARLY_DEV = 20462

const PADDLE_PRICE_ID_MONTHLY_PROD = 761272
const PADDLE_PRICE_ID_MONTHLY_DEV = 20461

const PAYPRO_PRICE_ID_MONTHLY_PROD = 103226
const PAYPRO_PRICE_ID_MONTHLY_DEV = 103225

const PAYPRO_PRICE_ID_YEARLY_PROD = 103227
const PAYPRO_PRICE_ID_YEARLY_DEV = 103228

const PADDLE_PRICE_ID_YEARLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_YEARLY_DEV : PADDLE_PRICE_ID_YEARLY_PROD
const PADDLE_PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_MONTHLY_DEV : PADDLE_PRICE_ID_MONTHLY_PROD
const PRODUCT_ID = isDevOrPreviewSite() ? PRODUCT_ID_DEV : PRODUCT_ID_PROD
const PRICE_ID_YEARLY = isDevOrPreviewSite() ? PRICE_ID_YEARLY_DEV : PRICE_ID_YEARLY_PROD
const PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PRICE_ID_MONTHLY_DEV : PRICE_ID_MONTHLY_PROD
const PAYPRO_PRICE_ID_YEARLY = isDevOrPreviewSite() ? PAYPRO_PRICE_ID_YEARLY_DEV : PAYPRO_PRICE_ID_YEARLY_PROD
const PAYPRO_PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PAYPRO_PRICE_ID_MONTHLY_DEV : PAYPRO_PRICE_ID_MONTHLY_PROD

const PRO_U_PLAN = {
  id: PRODUCT_ID,
  label: 'Pro',
  prices: {
    yearly: {
      id: PRICE_ID_YEARLY,
      value: 372,
      paddleLink: 'https://buy.paddle.com/product/761274',
      paddleId: PADDLE_PRICE_ID_YEARLY,
      payProId: PAYPRO_PRICE_ID_YEARLY
    },
    monthly: {
      id: PRICE_ID_MONTHLY,
      value: 38,
      paddleLink: 'https://buy.paddle.com/product/761272',
      paddleId: PADDLE_PRICE_ID_MONTHLY,
      payProId: PAYPRO_PRICE_ID_MONTHLY
    }
  },
  fileSizeLimit: 10000,
  customDomainsLimit: 5,
  projectLimit: 999,
  accountLimit: 10000,
  visitorLimit: 500000,
  bandwidthLimit: 100,
  features: {
    [F_CUSTOM_DOMAIN]: true,
    [F_PWD_PROTECTION]: true,
    [F_EDIT_MODE]: true,
    [F_API_KEY]: true,
    [F_EMAIL_GATE]: true
  }
}

export default PRO_U_PLAN
