import axs from 'axios'

export const baseURL = process.env.API_URL

const axios = axs.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default {
  async get(url, params, headers, config) {
    try {
      return await axios.get(url, {
        params,
        headers: {
          ...headers,
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        ...config
      })
    } catch (err) {
      throw err
    }
  },
  async post(url, params, headers, uploadProgress) {
    try {
      return await axios.post(url, params, {
        headers: {
          ...headers,
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        onUploadProgress: uploadProgress
      })
    } catch (err) {
      if (err.response) {
        throw err.response.data
      } else {
        throw err
      }
    }
  },

  async put(url, params, headers, uploadProgress) {
    try {
      return await axios.put(url, params, {
        headers: {
          ...headers,
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        onUploadProgress: uploadProgress
      })
    } catch (err) {
      if (err.response) {
        throw err.response.data
      } else {
        throw err
      }
    }
  }

  /*  async put(url, params, headers) {
      try {
        const response = await axios.put(url, params, { headers: { ...headers } });
        return response;
      } catch (err) {
        throw err;
      }
    },
    async patch(url, params, headers) {
      try {
        const response = await axios.patch(url, params, { headers: { ...headers } });
        return response;
      } catch (err) {
        throw err;
      }
    },
    async delete(url, params, headers) {
      try {
        return await axios.delete(url, { params, headers: { ...headers } });
      } catch (err) {
        throw err;
      }
    },
    axios,*/
}
