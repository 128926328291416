import React from 'react'
import styles from './DomainCell.module.css'
import CustomTooltip from '../../../CustomTooltip'
import { copyToClipboard } from '../../../../utils/general'

const DomainCell = ({ label, href, site, handlePreviewClick, disabled }) => {
  const onDomainClick = (e) => {
    if (disabled) return undefined

    e.preventDefault()
    if (site?.linkPreview) {
      handlePreviewClick(site)
    } else {
      window.open(href, '_blank')
    }
  }
  const updatedLabel = !site?.linkPreview ? label : 'Click to preview'

  return (
    <div className={styles.container}>
      <a
        onClick={onDomainClick}
        className={`domain-cell ${styles.link} ${disabled ? styles.disabled : ''}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className={styles.text}>{updatedLabel}</span>
      </a>
      <CustomTooltip label="Copy link">
        <button
          className={`${styles.copyLinkBtn} copy-domain-btn`}
          onClick={() => copyToClipboard(href)}
          disabled={disabled}
        >
          <img className="op-6" height="16" src="assets/icons/copy-link.svg" alt="tooltip" />
        </button>
      </CustomTooltip>
    </div>
  )
}

export default DomainCell
