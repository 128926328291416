import {
  ACCOUNT_MODAL,
  ACTION_PROCESSING,
  CREATE_SITE_MODAL,
  CUSTOM_DOMAIN_MODAL,
  DRAGGED_FILE,
  SITE_TYPE,
  FETCHING_USER,
  HIDE_MESSAGE_MODAL,
  INSERT_USER_PROFILE,
  SET_ACTION,
  SET_API_KEY,
  SET_QRCODE_DATA,
  SHOW_FIRST_TIME_MODAL,
  SHOW_MESSAGE_MODAL,
  SHOW_QRCODE_MODAL,
  TOGGLE_MODAL,
  TOGGLE_TEAM_MODAL,
  UPGRADE_MODAL,
  SET_EMBED_DATA,
  SET_PREVIEW_DATA,
  SHOW_PREVIEW_MODAL,
  TOGGLE_SIGNUP_MODAL,
  SET_LOADING_SITES_STORE,
  RESET_USER_PROFILE,
  SHOW_ACCOUNT_PASSWORD_MODAL
} from '../Manage/actions'

import { ACTION_CREATE } from '../components/CreateUpdateSiteModal/interface'

const initState = {
  userProfile: {},
  archivedSites: [],
  draggedFile: null,
  siteType: null,
  liveSites: [],
  newSiteData: {},
  QRCodeData: {},
  embedData: {},
  showQRCodeModal: false,
  previewData: undefined,
  showPreviewModal: false,
  showSignUpModal: false,
  showAccountPasswordModal: false,
  customDomains: [],
  accountModalVisible: false,
  showMessageModal: false,
  showFistTimeSuccModal: false,
  modalCustomDomainVisible: false,
  modalMessage: undefined,
  createSiteModal: {
    visible: false,
    data: {},
    action: ACTION_CREATE
  },
  displayModal: false,
  members: [],
  coupon: {
    isDiscount: false,
    percent_off: null
  },
  loadingSitesStore: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case INSERT_USER_PROFILE:
      const { userProfile, liveSites, archivedSites, customDomains, members, apiKey } = action.payload
      return {
        ...state,
        userProfile: {
          ...userProfile,
          apiKey: apiKey?.key
        },
        liveSites,
        archivedSites,
        customDomains,
        members
      }
    case ACTION_PROCESSING:
      return {
        ...state,
        actionProcessing: action.payload
      }
    case FETCHING_USER:
      return {
        ...state,
        fetchingUser: action.payload
      }
    case ACCOUNT_MODAL:
      return {
        ...state,
        accountModalVisible: action.payload
      }
    case UPGRADE_MODAL:
      return {
        ...state,
        upgradeModalVisible: action.payload
      }
    case TOGGLE_MODAL:
      return {
        ...state,
        displayModal: action.payload
      }
    case TOGGLE_TEAM_MODAL:
      return {
        ...state,
        showTeamModal: action.payload
      }
    case SHOW_FIRST_TIME_MODAL:
      return {
        ...state,
        showFistTimeSuccModal: action.payload
      }
    case SHOW_MESSAGE_MODAL:
      return {
        ...state,
        modalMessageData: action.payload,
        showMessageModal: true
      }
    case HIDE_MESSAGE_MODAL:
      return {
        ...state,
        modalMessageData: undefined,
        showMessageModal: false
      }
    case SET_QRCODE_DATA:
      return {
        ...state,
        QRCodeData: action.payload
      }
    case SHOW_QRCODE_MODAL:
      return {
        ...state,
        showQRCodeModal: action.payload
      }

    case SET_EMBED_DATA:
      return {
        ...state,
        embedData: action.payload
      }
    case CUSTOM_DOMAIN_MODAL:
      return {
        ...state,
        customDomainModalVisible: action.payload
      }
    case DRAGGED_FILE:
      return {
        ...state,
        draggedFile: action.payload
      }
    case SITE_TYPE:
      return {
        ...state,
        siteType: action.payload
      }

    case CREATE_SITE_MODAL:
      return {
        ...state,
        createSiteModal: action.payload
      }

    case SET_API_KEY:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          apiKey: action.payload
        }
      }

    case SET_ACTION:
      return {
        ...state,
        createSiteModal: {
          ...state.createSiteModal,
          action: action.payload
        }
      }

    case SET_PREVIEW_DATA:
      return {
        ...state,
        previewData: action.payload
      }

    case SHOW_PREVIEW_MODAL:
      return {
        ...state,
        showPreviewModal: action.payload
      }

    case TOGGLE_SIGNUP_MODAL:
      return {
        ...state,
        showSignUpModal: action.payload
      }

    case RESET_USER_PROFILE:
      return {
        ...initState
      }

    case SET_LOADING_SITES_STORE:
      return {
        ...state,
        loadingSitesStore: action.payload
      }

    case SHOW_ACCOUNT_PASSWORD_MODAL:
      return {
        ...state,
        showAccountPasswordModal: action.payload
      }

    default:
      return state
  }
}
