import api from '../utils/api'
import { API_ROOT } from '../constants'

export const downloadSite = (formData, config) => api.get(`${API_ROOT}/pro/download`, formData, undefined, config)

export const configureProAccount = (formData, config) => api.post(`${API_ROOT}/configure-account`, formData, config)

export const configureTrialAccount = (formData, config) =>
  api.post(`${API_ROOT}/configure-trial-account`, formData, config)

export const updateMetadata = (formData, config) => api.post(`${API_ROOT}/update-metadata`, formData, config)

export const getSiteAnalytics = (formData, config) => api.get(`${API_ROOT}/pro/site-analytics`, formData, config)

export const getTrialAnalytics = (formData, config) => api.post(`${API_ROOT}/trial-analytics`, formData, config)

export const upgradeAccount = (formData, config) => api.post(`${API_ROOT}/pro/upgrade`, formData, config)

export const cancelSubscription = (formData, config) => api.post(`${API_ROOT}/cancel-sub`, formData, config)

export const uncancelSubscription = (formData, config) => api.post(`${API_ROOT}/uncancel-sub`, formData, config)

export const updateTeamMembers = (formData, config) => api.post(`${API_ROOT}/pro/members`, formData, config)

export const manageBilling = (formData, config) => api.post(`${API_ROOT}/pro/manage-billing`, formData, config)

export const getAPIKey = (formData, config) => api.get(`${API_ROOT}/pro/api-key`, formData, config)

export const getFileCode = (formData, config) => api.get(`${API_ROOT}/pro/code`, formData, config)

export const claimSite = (formData, config) => api.post(`${API_ROOT}/pro/claim-site`, formData, config)

export const getPayProCheckoutUrl = (formData, config) =>
  api.post(`${API_ROOT}/generate-checkout-url`, formData, config)
