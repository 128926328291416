import React, { useState, useCallback } from 'react'
import Footer from '../components/Footer'
import HomeHeader from '../components/HomePageHeader'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import PlanPaymentModal from '../components/PlanPaymentModal/PlanPaymentModal'
import APIPlanCard from './components/APIPlanCard'
import FAQ from '../Help/components/faq'
import API_FAQS from './components/APIFAQs'
import API_PLAN from '../constants/plans/api'

import styles from './API.module.css'

const curlCommand = `curl --location 'https://ext.tiiny.host/v1/upload' \\
--header 'x-api-key: XXXXX-XXXXX-XXXXXX' \\
--form 'files=@"/path/to/file/file.html"'`

const APIService = () => {
  const [copied, setCopied] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(curlCommand)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }, [])

  const onPaymentModalHide = useCallback(() => {
    setShowPaymentModal(false)
  }, [])

  const onBuyClick = useCallback(() => {
    setShowPaymentModal(true)
  }, [])

  return (
    <>
      <PlanPaymentModal
        key={showPaymentModal ? 'modal-open' : 'modal-closed'}
        show={showPaymentModal}
        onHide={onPaymentModalHide}
        planId={API_PLAN.id}
        periodProp="monthly"
      />

      <Helmet>
        <title>Tiiny API - The simplest API to generate links</title>
        <meta property="og:title" content="Tiiny API - The simplest API to generate link" />
        <meta property="og:description" content="The simplest API to host & share your work online as link." />
        <meta name="description" content="The simplest API to host & share your work online as link." />
      </Helmet>

      <HomeHeader />

      <div className="container text-start mb-5 pb-5">
        <div className="mt-5 w-75 ms-auto me-auto text-center pb-5 pt-5">
          <h1 className={`${styles.h1} gradient-text`}>The simplest API to host & share your work online</h1>
          <p className={styles.subtitle}>Programmatically generate links for HTML, documents & images</p>
          <Button
            className="mt-5"
            size="lg"
            href="https://tiiny.host/api-docs"
            target="_blank"
            variant="outline-secondary"
          >
            View Docs
          </Button>
          <Button className="mt-5 ms-2" size="lg" onClick={onBuyClick}>
            Get Started
          </Button>
        </div>

        <div className="text-center mt-3 mb-5 p-4 rounded">
          <h2 className="bold">Instantly turn files into links</h2>
          <p className="grey font-18">Programmatically convert different file types into shareable website links.</p>
          <img className="mt-5 m-auto" src="assets/img/api.png" width="100%" style={{ maxWidth: 900 }} />
        </div>

        <div className="code-section mt-5 mb-3 p-4 rounded">
          <h2 className="bold">
            Simple to call. Easy to Integrate.
            <Button
              className="ms-2"
              size="sm"
              href="https://tiiny.host/api-docs"
              target="_blank"
              variant="outline-secondary"
            >
              View Docs
            </Button>
          </h2>
          <p className="grey font-18">Connect in minutes with straight forward endpoints & documentation.</p>
          <div className=" position-relative bg-dark p-4 rounded">
            <pre className="text-light mb-0">
              <code>{curlCommand}</code>
            </pre>
            <Button className="position-absolute top-0 end-0 m-3" variant="outline-light" onClick={handleCopy}>
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </div>
        </div>
      </div>

      <div className="container padded-section text-center mb-5">
        <APIPlanCard onClick={onBuyClick} />
      </div>

      <div className="container padded-section text-center mt-5 pt-2 pl-4 pr-4 mb-5">
        <FAQ faqs={API_FAQS} />
      </div>

      <Footer />
    </>
  )
}

export default APIService
